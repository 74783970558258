import React, { useState } from 'react'
import Layout from '../components/layouts'
import { Footer } from '../components/shareable/footer/Footer'
import { Flex, Text } from '../components/primitives'

export default ({ navigate }) => {
  const [modalIsOpen, setIsModalOpen] = useState(false)

  return (
    <Layout
      modelOpenProps={modalIsOpen}
      onCloseModal={() => {
        setIsModalOpen(false)
      }}
      headTitle={'Hållbarhet | Hemset.se'}
      blackNav={true}>
      <Flex jusifyContent={'center'}>
        <Flex
          mt={['80px', '80px', '80px']}
          maxWidth={'1000px'}
          minHeight={'100vh'}
          p={4}
          flexDirection={'column'}>
          <Text fontWeight={'bold'} fontSize={8} mb={2}>
            Hållbarhet
          </Text>

          <Text mb={2}>
            Vi strävar efter att ha en hållbarhetskärna i det vi gör. Hårt
            arbete ska vara väl balanserat med naturen, människorna och
            ekonomin. Det är viktigt att ha hållbarhet som en av tyngdpunkterna
            vid kommunikation med kunder, inredare och leverantörer. Vi vill
            inte enbart inspirera till ett trevligt hem utan också till att få
            ett hållbart hem. Vi strävar efter att ge kunskap om hur människors
            inredning kan användas under en längre tid. Genom detta krävs att de
            leverantörer vi väljer att binda avtal med, även har ett
            hållbarhetsarbete som går i linje med oss. Leverantören vi väljer
            bör vara transparenta med sitt hållbarhetsarbete, ha uppförandekoder
            som reglerar deras underleverantörer, vara medvetna om sin
            miljöpåverkan eller aktivt jobba för att bli det.
          </Text>
          <Text mb={2}>
            Vi har ett ansvar att veta vilken miljöpåverkan vi som företag har
            och vi anser att det är förutsättningen för att kunna driva ett
            företag i långsiktigt. Vi strävar hela tiden för att kunna ha en
            hållbarare affärsmodell och inser att det är en resa som kräver sitt
            arbete. Översiktligt just nu jobbar vi för att:
          </Text>

          <Text mb={2}>
            • Utbilda anställda inom ämnet och dess risker, även våra konsulter.
            <br />
            • Involvera alla anställda i att ta ansvar för att motverka påverkan
            på klimatet. <br />
            • Mål som sätts upp har klimatpåverkan i beräkning.
            <br />
            • Hemset ska i den mån som går medverka på hållbarhetsinitiativ för
            att dels kunna påverka men också lära sig av andra aktörer.
            <br />
            • Våra utvecklare skapar verktyg som underlättar och hjälper till på
            vägen mot mindre klimatpåverkan.
            <br />
            • Inspirera våra kunder genom content där klimatfokus ligger som en
            av grundpelarna.
            <br />• Ta initiativ som hjälper kunden att förlänga användningen av
            produkter i sina hem.
          </Text>
        </Flex>
      </Flex>
      <Footer
        actionClick={() => {
          setIsModalOpen(true)
        }}
      />
    </Layout>
  )
}
